import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Dashboard',
      auth: true
    },
    path: '/',
    name: 'home',
    component: Home
  },
  {
    meta: {
      title: 'Users',
      auth: true
    },
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/dashboard/Users.vue')
  },
  {
    meta: {
      title: 'States',
      auth: {
        roles: ['super-admin']
      }
    },
    path: '/states',
    name: 'states',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/dashboard/States.vue')
  },
  {
    meta: {
      title: 'schedules',
      auth: {
        roles: ['super-admin']
      }
    },
    path: '/schedules',
    name: 'schedules',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/dashboard/Schedule.vue')
  },
  {
    meta: {
      title: 'schedule',
      auth: {
        roles: ['super-admin']
      }
    },
    path: '/schedules/:id',
    name: 'schedule',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/dashboard/ScheduleInformation.vue')
  },
  {
    meta: {
      title: 'shipments',
      auth: {
        roles: ['super-admin']
      }
    },
    path: '/shipments',
    name: 'shipments',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/dashboard/Shipments.vue')
  },
  {
    meta: {
      title: 'shipment',
      auth: {
        roles: ['super-admin']
      }
    },
    path: '/shipments/:id',
    name: 'shipment',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/dashboard/Shipment.vue')
  },
  {
    meta: {
      title: 'trackings',
      auth: {
        roles: ['super-admin']
      }
    },
    path: '/trackings',
    name: 'trackings',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/dashboard/Trackings.vue')
  },
  {
    meta: {
      title: 'tracking',
      auth: {
        roles: ['super-admin']
      }
    },
    path: '/trackings/:id/:tracking_number',
    name: 'tracking',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/dashboard/Tracking.vue')
  },
  {
    meta: {
      title: 'notifications',
      auth: {
        roles: ['super-admin']
      }
    },
    path: '/notifications',
    name: 'notifications',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/dashboard/Notifications.vue')
  },
  {
    meta: {
      title: 'Profile',
      auth: true
    },
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue')
  },
  {
    meta: {
      title: 'New Client'
    },
    path: '/client/new',
    name: 'client.new',
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/ClientForm.vue')
  },
  {
    meta: {
      title: 'Edit Client'
    },
    path: '/client/:id',
    name: 'client.edit',
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/ClientForm.vue'),
    props: true
  },
  {
    meta: {
      title: 'Shipment Types'
    },
    path: '/shipment-types',
    name: 'shipment.types',
    component: () => import(/* webpackChunkName: "shipment-types" */ '@/views/dashboard/invoices/ShipmentTypes.vue')
  },
  {
    meta: {
      title: 'Shipment Type'
    },
    path: '/shipment-types/:type',
    name: 'shipment.type',
    component: () => import(/* webpackChunkName: "shipment-type" */ '@/views/dashboard/invoices/ShipmentType.vue')
  },
  {
    meta: {
      title: 'Year'
    },
    path: '/shipment-types/:type/years/:year',
    name: 'shipment.year',
    component: () => import(/* webpackChunkName: "shipment-year" */ '@/views/dashboard/invoices/Year.vue')
  },
  {
    meta: {
      title: 'Invoice'
    },
    path: '/shipment-types/:type/years/:year/invoices/:invoice',
    name: 'shipment.invoice',
    component: () => import(/* webpackChunkName: "shipment-invoice" */ '@/views/dashboard/invoices/Invoices.vue')
  },
  {
    path: '/full-page',
    component: () => import(/* webpackChunkName: "full-page" */ '@/views/FullPage.vue'),
    children: [
      // {
      //   meta: {
      //     title: 'Login',
      //     auth: false
      //   },
      //   path: '/login',
      //   name: 'login',
      //   component: () => import(/* webpackChunkName: "full-page" */ '@/views/full-page/Login.vue')
      // },
      {
        meta: {
          title: 'AccountDeletion'
        },
        path: '/account-deletion-process',
        name: 'account-deletion',
        component: () => import(/* webpackChunkName: "full-page" */ '@/views/full-page/AccountDeletion.vue')
      },
      {
        meta: {
          title: 'Clover Oauth Callback'
        },
        path: '/clover_oauth_callback',
        name: 'clover_oauth_callback',
        component: () => import(/* webpackChunkName: "full-page" */ '@/views/full-page/Clover.vue')
      }
    ]
  },
  {
    path: '/full-page-two',
    component: () => import(/* webpackChunkName: "full-page-two" */ '@/views/FullPageTwo.vue'),
    children: [
      {
        meta: {
          title: 'Login',
          auth: false
        },
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "full-page" */ '@/views/full-page/Login.vue')
      }
    ]
  }

]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router

export function useRouter () {
  return router
}
