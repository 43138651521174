<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      Dashboard
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-widget
          class="tile is-child"
          type="is-info"
          icon="account-multiple"
          :number="stats.users_count"
          label="Total Users"
        />
        <card-widget
          class="tile is-child"
          type="is-primary"
          icon="currency-usd"
          :number="stats.months.total"
          prefix="$"
          :label="'Income- ' + year"
        />
        <card-widget
          class="tile is-child"
          type="is-link"
          icon="account"
          :number="stats.customers_count"
          label="Clients"
        />
        <card-widget
          class="tile is-child"
          type="is-danger"
          icon="truck-fast"
          :number="stats.shipments_count"
          label="Shipments"
        />
      </tiles>
      <card-component
        :title="'Income in USD for ' + year"
        icon="finance"
        header-icon="reload"
        @header-icon-click="refreshData"
      >
        <div class="mb-4">
          <year-selector
            class="has-text-right"
            @update-year="onYearChange"
          />
        </div>
        <div
          v-if="options"
          class="chart-area"
        >
          <Apexchart
            height="350"
            width="100%"
            type="line"
            :options="options"
            :series="series"
          />
        </div>
      </card-component>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import Tiles from '@/components/Tiles.vue'
import CardWidget from '@/components/CardWidget.vue'
import CardComponent from '@/components/CardComponent.vue'
import YearSelector from '@/components/dashboard/YearSelector'

export default {
  name: 'Home',
  components: {
    YearSelector,
    CardComponent,
    CardWidget,
    Tiles,
    HeroBar,
    TitleBar
  },
  data () {
    return {
      titleStack: ['Admin', 'Dashboard'],
      year: new Date().getFullYear(),
      options: null,
      series: null,
      stats: null
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async refreshData () {
      await this.fetch()
      this.$buefy.snackbar.open({
        message: 'Refresh complete',
        queue: false
      })
    },
    fetch () {
      this.axios.get('/v1/app/stats/' + this.year)
        .then(response => {
          this.stats = response.data.stats
          this.options = {
            chart: {
              id: 'Income in USD for' + this.year
            },
            xaxis: {
              categories: this.stats.months.months
            },
            colors: ['#17561f']
          }
          this.series = [{
            name: `${this.year}`,
            data: this.stats.months.shipments_value
          }]
        })
        .catch(error => {
          console.log(error)
        })
    },
    onYearChange (year) {
      this.year = year
      this.fetch()
    }
  }
}
</script>
