<template>
  <nav
    v-show="isNavBarVisible"
    id="navbar-main"
    class="navbar is-fixed-top"
  >
    <div class="navbar-brand">
      <a
        class="navbar-item is-hidden-desktop"
        @click.prevent="asideToggleMobile"
      >
        <b-icon :icon="asideMobileIcon" />
      </a>
      <a
        class="navbar-item is-hidden-touch is-hidden-widescreen is-desktop-icon-only"
        @click.prevent="asideDesktopOnlyToggle"
      >
        <b-icon icon="menu" />
      </a>
      <div class="navbar-item has-control no-left-space-touch no-left-space-desktop-only">
        <div class="control" />
      </div>
    </div>
    <div class="navbar-brand is-right">
      <a
        class="navbar-item navbar-item-menu-toggle is-hidden-desktop"
        @click.prevent="menuToggle"
      >
        <b-icon
          :icon="menuToggleIcon"
          custom-size="default"
        />
      </a>
    </div>
    <div
      class="navbar-menu fadeIn animated faster"
      :class="{ 'is-active': isMenuActive }"
    >
      <div class="navbar-end">
        <!--        <nav-bar-menu class="has-divider">-->
        <!--          <b-icon-->
        <!--            icon="menu"-->
        <!--            custom-size="default"-->
        <!--          />-->
        <!--          <span>Sample Menu</span>-->
        <!--          <div-->
        <!--            slot="dropdown"-->
        <!--            class="navbar-dropdown"-->
        <!--          >-->
        <!--            <router-link-->
        <!--              to="/profile"-->
        <!--              class="navbar-item"-->
        <!--              exact-active-class="is-active"-->
        <!--            >-->
        <!--              <b-icon-->
        <!--                icon="account"-->
        <!--                custom-size="default"-->
        <!--              />-->
        <!--              <span>My Profile</span>-->
        <!--            </router-link>-->
        <!--            <a class="navbar-item">-->
        <!--              <b-icon-->
        <!--                icon="settings"-->
        <!--                custom-size="default"-->
        <!--              />-->
        <!--              <span>Settings</span>-->
        <!--            </a>-->
        <!--            <a class="navbar-item">-->
        <!--              <b-icon-->
        <!--                icon="email"-->
        <!--                custom-size="default"-->
        <!--              />-->
        <!--              <span>Messages</span>-->
        <!--            </a>-->
        <!--            <hr class="navbar-divider">-->
        <!--            <a class="navbar-item">-->
        <!--              <b-icon-->
        <!--                icon="logout"-->
        <!--                custom-size="default"-->
        <!--              />-->
        <!--              <span>Log Out</span>-->
        <!--            </a>-->
        <!--          </div>-->
        <!--        </nav-bar-menu>-->
        <nav-bar-menu class="has-divider has-user-avatar">
          <user-avatar :avatar="'https://ui-avatars.com/api/?background=17561f&color=fafafa&name=' + $auth.user().name" />
          <div class="is-user-name">
            <span>{{ $auth.user().name }}</span>
          </div>

          <div
            slot="dropdown"
            class="navbar-dropdown"
          >
            <router-link
              :to="{name: 'users'}"
              class="navbar-item"
            >
              <b-icon
                icon="account-multiple"
                custom-size="default"
              />
              <span>Users</span>
            </router-link>
            <router-link
              :to="{name: 'states'}"
              class="navbar-item"
            >
              <b-icon
                icon="earth"
                custom-size="default"
              />
              <span>States</span>
            </router-link>
            <router-link
              :to="{name: 'schedules'}"
              class="navbar-item"
            >
              <b-icon
                icon="clock-outline"
                custom-size="default"
              />
              <span>Schedules</span>
            </router-link>
            <router-link
              :to="{name: 'shipments'}"
              class="navbar-item"
            >
              <b-icon
                icon="truck-fast"
                custom-size="default"
              />
              <span>Shipments</span>
            </router-link>
            <router-link
              :to="{name: 'trackings'}"
              class="navbar-item"
            >
              <b-icon
                icon="truck-trailer"
                custom-size="default"
              />
              <span>Tracking</span>
            </router-link>
            <router-link
              :to="{name: 'notifications'}"
              class="navbar-item"
            >
              <b-icon
                icon="bell"
                custom-size="default"
              />
              <span>Notifications</span>
            </router-link>
            <router-link
              to="/profile"
              class="navbar-item"
              exact-active-class="is-active"
            >
              <b-icon
                icon="account"
                custom-size="default"
              />
              <span>My Profile</span>
            </router-link>
            <hr class="navbar-divider">
            <a
              class="navbar-item"
              @click.prevent="logout"
            >
              <b-icon
                icon="logout"
                custom-size="default"
              />
              <span>Log Out</span>
            </a>
          </div>
        </nav-bar-menu>
        <a
          class="navbar-item is-desktop-icon-only"
          title="Log out"
          @click="logout"
        >
          <b-icon
            icon="logout"
            custom-size="default"
          />
          <span>Log out</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import NavBarMenu from '@/components/NavBarMenu.vue'
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  name: 'NavBar',
  components: {
    UserAvatar,
    NavBarMenu
  },
  data () {
    return {
      isMenuActive: false
    }
  },
  computed: {
    asideMobileIcon () {
      return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
    },
    menuToggleIcon () {
      return this.isMenuActive ? 'close' : 'dots-vertical'
    },
    ...mapState([
      'isAsideMobileExpanded',
      'isNavBarVisible',
      'userName'
    ])
  },
  mounted () {
    this.$router.afterEach(() => {
      this.isMenuActive = false
    })
  },
  methods: {
    asideToggleMobile () {
      this.$store.commit('asideMobileStateToggle')
    },
    asideDesktopOnlyToggle () {
      this.$store.dispatch('asideDesktopOnlyToggle')
    },
    menuToggle () {
      this.isMenuActive = !this.isMenuActive
    },
    logout () {
      this.$auth.logout({
        makeRequest: true,
        redirect: { name: 'login' }
      }).then(() => {
        this.$buefy.snackbar.open({
          message: 'Logged out!',
          queue: false
        })
      })
    }
  }
}
</script>
