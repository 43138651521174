import Vue from 'vue'

Vue.mixin({
  methods: {
    calculateBalance (paid, total) {
      if (paid === null) {
        return total.toFixed(2)
      }
      return (total - paid).toFixed(2)
    }
  }
})
