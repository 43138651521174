<template>
  <div>
    <b-dropdown
      v-model="currentMenu"
      :scrollable="true"
      :max-height="200"
      aria-role="list"
      position="is-bottom-left"
    >
      <template #trigger>
        <b-button
          :label="currentMenu.text"
          type="is-success is-small"
          :icon-left="currentMenu.icon"
          icon-right="menu-down"
        />
      </template>
      <b-dropdown-item
        v-for="(menu, index) in menus"
        :key="index"
        :value="menu"
        aria-role="listitem"
        type="is-success"
        @click="onMenuChange"
      >
        <div class="media">
          <b-icon
            class="media-left"
            :icon="menu.icon"
          />
          <div class="media-content">
            <h3>{{ menu.text }}</h3>
          </div>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'YearSelector',
  data () {
    return {
      currentMenu: {
        text: `${new Date().getFullYear()}`,
        icon: 'calendar'
      },
      menus: [
        {
          text: '2021',
          icon: 'calendar'
        },
        {
          text: '2022',
          icon: 'calendar'
        },
        {
          text: '2023',
          icon: 'calendar'
        },
        {
          text: '2024',
          icon: 'calendar'
        },
        {
          text: '2025',
          icon: 'calendar'
        },
        {
          text: '2026',
          icon: 'calendar'
        },
        {
          text: '2027',
          icon: 'calendar'
        }
      ]
    }
  },
  methods: {
    onMenuChange () {
      this.$emit('update-year', this.currentMenu.text)
    }
  }
}
</script>

<style scoped>

</style>
