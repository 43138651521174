export default [
  'General',
  [
    {
      to: '/',
      icon: 'desktop-mac',
      label: 'Dashboard'
    }
  ],
  'Menu',
  [
    {
      to: '/users',
      label: 'Users',
      icon: 'account-multiple'
    },
    {
      to: '/states',
      label: 'States',
      icon: 'earth'
    },
    {
      to: '/schedules',
      label: 'Schedules',
      icon: 'clock-outline'
    },
    {
      to: '/shipments',
      label: 'Shipments',
      icon: 'truck-fast'
    },
    {
      to: '/trackings',
      label: 'Tracking',
      icon: 'truck-trailer'
    },
    {
      to: '/notifications',
      label: 'Notifications',
      icon: 'bell'
    },
    {
      to: '/shipment-types',
      label: 'Linctech',
      icon: 'truck'
    },
    {
      to: '/profile',
      label: 'Profile',
      icon: 'account-circle'
    }
  ]
]
